@import url(https://fonts.googleapis.com/css?family=Roboto:300);

.form-container {
  width: 100%;
  background-color: rgb(255, 255, 255);
  margin-top: auto;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  padding: 10px;
  border-radius: 10px;
}

.register-form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10px;
}

.form-field {
  margin: 10px 0 10px 0;
  padding: 5%;
  font-size: 16px;
  border: 0;
  font-family: "Roboto", sans-serif;
  background: #f2f2f2;
}

input,
select,
textarea,
.checkbox-group {
  background: #f2f2f2;
}

.error {
  border-style: solid;
  border: 2px solid #ffa4a4;
}

button:hover {
  background-color:rgb(205, 216, 216) ;
  color: white;
}

button:disabled {
  cursor: default;
}

.checkbox-group {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0 10px 0;
  padding: 5%;
  font-size: 16px;
  border: 0;
  font-family: "Roboto", sans-serif;
}
.error-message{
  color: #ffa4a4;
  
}

