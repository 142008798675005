.homepageContainer {
  height: 100vh;
  padding: 0;
  margin-top: 70px;
}
.welcomeHeaderSection {
  margin-left: 50px;
  padding-top: 50px;
  color: #19801d;
}
.card_container { 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.rowWithSpace{
  margin-bottom: 20px;
}
.customCardDesign .ant-card-head{
  background-color: #C8E6C9;
  text-align: center;
}
/* .customCardDesign .ant-card-body{
  padding: 0;
} */
.customCardDesign{
  height: 180px;
}
.cardInnerHeader {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.cardInnerBody{
  display: flex;
  flex-direction: column;
  width: 50%;
  text-align: center;
}

/* .logo-container {
    margin-bottom: 20px;
    animation: spin 10s infinite linear; 
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .logo-image {
    width: 250px;
    height: 250px;
  }

  
  h1 {
    font-size: 24px;
    color: #333;
  } */
