.page-container {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    background-color: #f2f2f2;
    padding: 0;
  }

  .content-container {
    width: 95%;
  }