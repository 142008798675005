.login-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Add this line to include padding in the max width/height calculations */
  margin: auto auto; /* Add this line to center the login form horizontally */
  margin-top: 10%; /* Add this line to center the login form vertically */
  max-width: 400px;
  max-height: 600px;
  padding: 5%;
}


.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  font-weight: bold;
}

.form-control {
  
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn-primary {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  text-align: center;
  justify-content: center;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #85ca4d;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


.logo-box img {
  max-width: 100px; /* Adjust the logo size as needed */
}


